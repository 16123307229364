<template>
  <v-row>
    <v-col md="6">
      <v-autocomplete
        id="filter-customer"
        dense
        v-model="customer"
        item-color="cyan"
        filled
        :search-input.sync="customer_search"
        :items="customer_list"
        hide-details
        :loading="customer_loading"
        placeholder="Select Customer"
        label="Select Customer"
        v-on:update:search-input="customerFilter"
        solo
        flat
        clearable
        return-object
        color="cyan"
        v-on:change="getLocations($event)"
      />
    </v-col>
    <v-col md="6">
      <v-autocomplete
        id="filter-billing-location"
        dense
        v-model="billingId"
        item-color="cyan"
        filled
        :items="billing_list"
        hide-details
        :loading="billing_loading"
        placeholder="Select Billing Location"
        label="Select Billing Location"
        solo
        flat
        clearable
        color="cyan"
        v-on:change="emitValue()"
      />
    </v-col>
    <v-col md="6">
      <v-autocomplete
        id="filter-property-location"
        dense
        v-model="propertyId"
        item-color="cyan"
        filled
        :items="property_list"
        hide-details
        :loading="property_loading"
        placeholder="Select Site Location"
        label="Select Site Location"
        solo
        flat
        clearable
        color="cyan"
        v-on:change="emitValue()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { SET_CUSTOMER_LISTING_FILTER } from "@/core/services/store/customer.module";
import { GET, QUERY } from "@/core/services/store/request.module";
import { toSafeInteger } from "lodash";

export default {
  name: "customer-filter-listing",
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      customerId: null,
      customer: null,
      customer_search: null,
      customer_loading: false,
      customer_list: [],
      billingId: null,
      billing_loading: false,
      billing_list: [],
      propertyId: null,
      property_loading: false,
      property_list: [],
      searchTimeout: null,
      searchTimeoutLimit: 500,
    };
  },
  methods: {
    emitValue() {
      this.$store.commit(SET_CUSTOMER_LISTING_FILTER, {
        customer: this.customerId,
        billing: this.billingId,
        property: this.propertyId,
      });
    },
    getLocations(param) {
      this.customerId = param?.id ?? 0;
      this.billingId = 0;
      this.propertyId = 0;
      this.getBillingLocations();
      this.getPropertyLocations();
      this.emitValue();
    },
    customerFilter() {
      clearTimeout(this.searchTimeout);

      if (!this.customer_search) {
        return false;
      }

      let selected = `${this.customer?.barcode} - ${this.customer?.display_name}`;
      if (selected?.toUpperCase() == this.customer_search?.toUpperCase()) {
        return false;
      }

      this.customer_list = [];
      this.billing_list = [];
      this.property_list = [];
      this.searchTimeout = setTimeout(() => {
        this.getCustomers();
      }, this.searchTimeoutLimit);
    },
    getCustomers() {
      this.customer_loading = true;
      this.$store
        .dispatch(QUERY, {
          url: "filter-customer",
          data: { search: this.customer_search },
        })
        .then(({ data }) => {
          this.customer_list = data;
          if (this.customerId) {
            this.customer = this.lodash.find(this.customer_list, {
              id: this.customerId,
            });
          }
          this.getBillingLocations();
          this.getPropertyLocations();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.customer_loading = false;
        });
    },
    getBillingLocations() {
      this.billing_list = [];
      if (!this.customerId) {
        return false;
      }
      this.billing_loading = true;
      this.$store
        .dispatch(GET, { url: `filter-billing-location/${this.customerId}` })
        .then(({ data }) => {
          this.billing_list = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.billing_loading = false;
        });
    },
    getPropertyLocations() {
      this.property_list = [];
      if (!this.customerId) {
        return false;
      }
      this.property_loading = true;
      this.$store
        .dispatch(GET, { url: `filter-property-location/${this.customerId}` })
        .then(({ data }) => {
          this.property_list = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.property_loading = false;
        });
    },
  },
  mounted() {
    this.getCustomers();
    this.customerId = toSafeInteger(this.$route.query?.customer);
    this.billingId = toSafeInteger(this.$route.query?.billing);
    this.propertyId = toSafeInteger(this.$route.query?.property);
  },
};
</script>
